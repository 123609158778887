@use 'src/app/variables' as *;

.outerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--io-spacing-1);

  cursor: pointer;
  transition: transform 350ms ease;

  padding: 0;
  border: none;
  background: none;

  &:not(.withLabel) {
    border-radius: 50%;
  }

  &[disabled] {
    pointer-events: none;
    color: var(--io-text-disabled);
  }

  &:active {
    transform: scale(0.95);
  }
}

.iconButton {
  --button-size-large: #{toRem(40)};
  --icon-size-large: #{toRem(22)};

  --button-size-medium: #{toRem(36)};
  --icon-size-medium: #{toRem(19)};

  --button-size-small: #{toRem(28)};
  --icon-size-small: #{toRem(14)};

  --button-size-tiny: #{toRem(24)};
  --icon-size-tiny: #{toRem(13)};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 50%;

  height: var(--button-size, var(--button-size-large));
  width: var(--button-size, var(--button-size-large));
}

.outerButton[disabled] .iconButton {
  background-color: var(--io-bg-disabled);
  color: var(--io-text-disabled);
  border-color: var(--io-bg-disabled);
}

.iconWrapper {
  display: inline-block;
  height: auto;
  width: auto;
}

.label {
  color: var(--io-text);
  font-size: var(--io-font-size-label);
}

/**
 * Sizing
 */

.icon {
  width: var(--icon-size, var(--icon-size-large));
  height: var(--icon-size, var(--icon-size-large));
}

.large {
  --button-size: var(--button-size-large);
  --icon-size: var(--icon-size-large);
}
.medium {
  --button-size: var(--button-size-medium);
  --icon-size: var(--icon-size-medium);
}
.small {
  --button-size: var(--button-size-small);
  --icon-size: var(--icon-size-small);
}
.tiny {
  --button-size: var(--button-size-tiny);
  --icon-size: var(--icon-size-tiny);
}

/**
 * Variants
 */

.transparent {
  color: var(--io-icon-button-transparent-fill);
  background-color: var(--io-icon-button-transparent-bg);

  &:hover,
  &:focus-visible {
    background-color: var(--io-icon-button-transparent-bg-hover);
  }
}

.light {
  color: var(--io-icon-button-light-fill);
  background-color: var(--io-icon-button-light-bg);

  &:hover,
  &:focus-visible {
    background-color: var(--io-icon-button-light-bg-hover);
  }
}
.outerButton:active .light {
  background-color: var(--io-icon-button-light-bg-hover);
}

.solidLight {
  color: var(--io-icon-button-solid-light-fill);
  background-color: var(--io-icon-button-solid-light-bg);
  border: 1px solid var(--io-icon-button-solid-light-fill);

  &:hover,
  &:focus-visible {
    background-color: var(--io-icon-button-solid-light-bg-hover);
  }
}

.dark {
  color: var(--io-icon-button-dark-fill);
  background-color: var(--io-icon-button-dark-bg);

  &:hover,
  &:focus-visible {
    background-color: var(--io-icon-button-dark-bg-hover);
  }
}
